import creditcards from "../images/creditcards.jpg";

export const content = {
  header: "Lightning-fast internet.",
  header2: "Crazy low price.",
  comingSoon: "Coming Soon",
  body1:
    "A new kind of internet is coming to Fort Carson, and it’s going to blow your socks off.",
  body2: "Join our VIP list and we’ll update you as soon as it’s available.",
  body3:
    " Be the first in line to discover the joy of Boingo Fiber. No socks required.",
  selectedAddressHeader: "Here’s the address that you chose that qualifies",
  fNameError: "Enter valid first name",
  lNameError: "Enter valid last name",
  emailError: "Enter valid email address",
  phoneNumberError: "Enter valid phone number",
  addressError: "Enter a valid address",
  note: "By checking this box, you consent to receive updates and marketing communications via SMS. You can change your preference at any time.",
  privacy: "Privacy Policy",
  completedHeaderGetReady: "Get ready for",
  completedHeaderLightnings: "lightning-fast internet,",
  completedHeaderAt: "at a",
  completedHeaderLowPrice: "Crazy low price.",
  completedBody:
    "Stay tuned! We’ll let you know when Boingo Fiber is ready in your area.",

  selectAddress: "Select an address to see if you qualify",
  siteLabel: "Site/Subdivision",
  streetNameLabel: "Street Name",
  streetTypeLabel: "Street Type",
  houseNumberLabel: "House Number",
  unitLabel: "Unit",
  selectSite: "Select a Site",
  selectStreetName: "Select a street name",
  selectStreetType: "Select a street type",
  selectHouseNumber: "Select a house number",
  selectUnit: "Select a unit",
  submitText: "Check Address",
  welcomeSubhead1:
    "Now that you're signed up, let's get you connected.",
  welcomeSubhead2:
    "Don’t worry—setup is simple! If you can plug in a cord, you’re halfway there!",
  welcomeWyn1: "A whole home Wi-Fi system",
  welcomeWyn2: "An ethernet cable",
  welcomeWyn3:
    "To set up Wi-Fi, follow the instructions that came with your router. Then connect devices (phone, laptop, tablet, gaming console) to the network you create.",
  welcomeWyn4: " these specifications.",
  welcomeWyn5:
    "The Exchange on base has many options that are affordable and easy to connect. Look for a “Works with Boingo Fiber” sticker on the box. Here are a few we recommend:",
  welcomeWynTplink: "TP-Link Deco 3600W",
  welcomeWynEero: "Amazon Eero 6",
  welcomeWynDlink: "D-Link AX1500",
  welcomeWynGoogle: "Google AC1200",
  welcomeSetup:
    "Set-up is as easy as plugging your equipment into your Boingo Box.",
  welcomeSetupHeader1: "Easy set up!",
  welcomeSetupHeader2: "",
  welcomeSetupHeader3: "",
  welcomeSetupDescription1:
    "Start by ensuring the Boingo Box is plugged in. The power button is the orange button on the bottom right side. It takes about one minute to come on, and you’ll know it’s on when you see two green lights at the top right.",
  welcomeSetupDescription2: "Plug in the base station into a power outlet.",
  welcomeSetupDescription3:
    "Follow the instructions that came with your equipment to set up a home Wi-Fi network or additional equipment.",
  welcomeSetupSubdescription1:
    'Plug one end of an ethernet cable into the Boingo Box on your wall, and the other end into your system’s base station. The base station may be called a "router" or "gateway" depending on the manufacturer.',
  welcomeSetupSubdescription2: "",
  welcomeSetupSubdescription3:
    "Many systems use a mobile app to complete this step.",
  welcomeHelpSubhead1:
    "Visit Boingo Support for answers to commonly asked questions. And of course award-winning",
  welcomeHelpSubhead2: "Customer Care ",
  welcomeHelpSubhead3: "is here 24/7/365 to help.",
  welcomeWelcomeTo: "welcome to",
  routerSteps: {
    one: "Plug your router into the Boingo ethernet port in your room",
    two: "Follow the instructions that came with your router to connect a laptop or mobile device. You can use a wired or wireless connection.",
    three: "Open a browser and you'll be automatically directed to the Boingo Fiber sign-up page. If a page doesn't appear, type boingo.com into your browser.",
  },
  paymentAddress: "Here’s the location we have for you",
  resetCredentialsHref: "https://selfcare.boingohotspot.net/accounthelp",
  faqs: [
    {
      text: "How do I sign up for Boingo Fiber?",
      array: [
        {
          id: 1,
          q: "Q. How do I sign up for Boingo Fiber?",
          a: [
            {
              type: "link-p",
              link: "https://www.boingo.com/fiber",
              linktext: "boingo.com/fiber",
              html1:
                "Scan the QR code on your Boingo Box or near your Ethernet Port. Or enter the URL ",
              html2: " on your device.",
            },
          ],
        },
        {
          id: 2,
          q: "Q. Where can I get Fiber service?",
          a: [
            {
              type: "p",
              html: "Boingo Fiber Service is available exclusively at Fort Carson Family Housing, Fort Johnson, and Fort Belvoir.",
            },
          ],
        },
        {
          id: 3,
          q: "Q. When will service be live?",
          a: [
            {
              type: "link-p",
              link: "https://fiber.boingo.com",
              linktext: "fiber.boingo.com",
              html1:
                "Boingo Fiber is launching on a rolling basis, going from community to community. If you’d like to be alerted when Boingo Fiber is available in your home, go to ",
              html2:
                ", enter your address, and sign up for our early bird registration. We’ll email you when it’s ready!",
            },
          ],
        },
        {
          id: 4,
          q: "Q. How fast is the internet speed?",
          a: [
            {
              type: "p",
              html: "Boingo Fiber is fast, reliable internet for your whole home. Boingo Fiber offers 1 Gig both upload and download with no data caps. ",
            },
          ],
        },
        {
          id: 5,
          q: "Q. How much does Boingo Fiber cost per month?",
          a: [
            {
              type: "p",
              html: "For 1 Gig upload and download speeds, Boingo Fiber is only $69/month with no hidden fees and no equipment charges.",
            },
          ],
        },
        {
          id: 6,
          q: "Q. What equipment is needed for Boingo Fiber?",
          a: [
            {
              type: "p",
              html: "Cable companies charge big monthly fees for their equipment. Boingo Fiber is different. Our “BYO’ plan means you use your own equipment, which saves you a bundle each month. Plus, you can bring our equipment to your next duty station.",
            },
            {
              type: "p",
              html: "To get started, you’ll need:",
            },
            {
              type: "list-with-first-line-bold",
              html1: "A router or ",
              html2: "Whole Home Wi-Fi system ",
              html3:
                "(sometimes referred to as a “Mesh Wi-Fi” system) depending on where you are located.",
              html4: ["An ethernet cable"],
            },
            {
              type: "bold-inline",
              html1: "",
              bold: "Pro Tip: Gigabit speeds need gigabit routers and systems! ",
              html2:
                "You are welcome to purchase or use one you already have. Just be sure it meets the following specifications:",
            },
            {
              type: "list",
              html: [
                "Supports gigabit wired speeds (The router’s WAN and LAN ports should be rated for Gigabit speeds, or what is often labeled as “10/100/1000 Mbps”)",
                "Wi-Fi 6 (802.11ax) or Wi-Fi 5 (802.11ac)",
                "80Mhz channel width",
                "Minimum WPA2 Security",
              ],
            },
            {
              type: "bold-line",
              html: "We highly recommend the following systems, which have been tested in our labs, and are available at the Exchange:",
            },
            {
              type: "list",
              html: [
                "D-Link – Eagle Pro AI",
                "TP-Link -- Deco",
                "Amazon – Eero 6",
                "Google – Google Wi-Fi",
                "Please note, we do not endorse any of the products on this list or make any type of affiliate fee. There are many that meet our guidelines; feel free to use any of them.",
              ],
            },
            {
              type: "bold-line",
              html: "Set Up is easy!",
            },
            {
              type: "list-ordered",
              html: [
                "Simply scan the QR code located on your Boingo Box (small white box with Boingo sticker), or you can plug a computer directly into the Boingo Box.",
                "Sign up for your Boingo account and choose your plan. ",
                "You’re connected! You can now connect your devices to your new router if applicable. ",
              ],
            },
          ],
        },
        {
          id: 7,
          q: "Q. When will the billing begin?",
          a: [
            {
              type: "p",
              html: "After you successfully sign up and plug in your device, billing will begin.",
            },
          ],
        },
        {
          id: 8,
          q: "Are there data limits? Can I run a business from my house using Boingo Fiber?",
          a: [
            {
              type: "p",
              html: "No data limits. No throttling. With fiber, you get super-fast, super reliable speeds, perfect for any business setting.",
            },
          ],
        },
        {
          id: "h1",
          q: "24/7/365 AWARD-WINNING CUSTOMER CARE",
          a: [
            {
              type: "support-p",
              link: "https://support.boingo.com/s/",
              tel: 8667264646,
              html1:
                "We get it. Not everyone is a Wi-Fi genius. If you get stuck setting up your equipment, we’re here to help! (We are, in fact, Wi-Fi geniuses.)",
              html2: "Boingo Support",
              html3: " has quick answers to our most common questions.",
              html4: "Our friendly and helpful agents are here 24/7/365 at ",
              html5: "866-726-4646",
              html6: ".",
            },
          ],
        },
      ],
    },
    {
      text: "How do I set up my Boingo Fiber account?",
      array: [
        {
          id: 1,
          a: [
            {
              type: "p",
              html: "Boingo Fiber is designed with military families in mind – it’s lightning-fast and sensibly priced, with no hidden fees, taxes, or contracts. It’s the internet you deserve.",
            },
            {
              type: "p",
              html: "Now let’s get you connected!",
            },
            {
              type: "p",
              html: "Having your own router or home Wi-Fi system means a stronger connection for your computer, gaming device, Bluetooth speakers, and more. The Exchange on base has several great options that work with Boingo Fiber and are easy to set up, you can also purchase your equipment online.",
            },
            {
              type: "bold-line",
              html: "Gigabit speeds need gigabit routers!",
            },
            {
              type: "p",
              html: "You are welcome to purchase any Whole Home Wi-Fi system or use one you already have. Just be sure it meets the following specifications:",
            },
            {
              type: "list",
              html: [
                "Supports gigabit wired speeds (The router’s WAN and LAN ports should be rated for Gigabit speeds, or what is often labeled as “10/100/1000 Mbps”)",
                "Wi-Fi 6 (802.11ax) or Wi-Fi 5 (802.11ac)",
                "80Mhz channel width",
                "Minimum WPA2 Security",
              ],
            },
            {
              type: "bold-inline",
              bold: "Please note: ",
              html1: "",
              html2:
                "if it does not comply with the specifications listed above, you will not receive your desired speed.",
            },
          ],
        },
        {
          id: 2,

          q: "Q. Need to purchase equipment?",
          a: [
            {
              type: "p",
              html: "The Exchange on base has several great Whole Home Wi-Fi systems that work with Boingo Fiber and are easy to set up. We highly recommend the following systems, which have been tested in our labs:",
            },
            {
              type: "list",
              html: [
                "D-Link – AX1500, 2-pack $89, 3-pack $120",
                "TP-Link – Deco W3600, 2-pack $99",
                "Amazon – Eero 6, 2-pack $110, 3-pack $160",
                "Google – AC1200, 1-pack $57, 2 pack $114",
                "Please note, we do not endorse any of the products on this list or make any type of affiliate fee. There are many that meet our guidelines; feel free to use any of them.",
              ],
            },
          ],
        },
        {
          id: 3,

          q: "Q. Can I use a Wi-Fi system from another service provider? ",
          a: [
            {
              type: "link-p",
              link: "https://www.boingo.com/fiber",
              linktext: "boingo.com/fiber",
              html1:
                "Unfortunately, the cable modem supplied by your service provider only works on their network. The Boingo Fiber network can work with most Whole Home Wi-Fi systems available at the Exchange or any retailer. Check out our router recommendations at ",
              html2: ".",
            },
          ],
        },
        {
          id: 4,

          q: "Q. How do I configure my router or home Wi-Fi network?",
          a: [
            {
              type: "p",
              html: "If you’ve lost the instructions to your Wi-Fi router or Home Wi-Fi system, you can use these generic instructions, which work with most systems:",
            },
            {
              type: "list",
              html: [
                "Open a web browser on your computer or mobile device",
                "In the address bar, type in the following address: 192.168.0.1",
                "If that doesn’t work, try 192.168.1.1",
                "Either one of these IP addresses will redirect you to your systems administration page, where you can set up security settings, change the Wi-Fi network name or password, and perform other configurations.",
              ],
            },
            {
              type: "p",
              html: "Once your home Wi-Fi network is set up, you can begin connecting devices and experiencing the incredible speeds of Boingo Fiber!",
            },
          ],
        },
        {
          id: "h2",
          q: "STEP 1: GATHER YOUR EQUIPMENT",
          a: [
            {
              type: "step-p1",
              html1:
                "Boingo Fiber is different. We don’t charge expensive monthly equipment fees. Our “BYO” (bring your own) plan means ",
              html2: "you use your own equipment",
              html3: "to plug into the Boingo Box mounted in your home.",
              html4: "To get started, you’ll need:",
              html5: [
                "A Whole Home Wi-Fi system (sometimes referred to as a “Mesh Wi-Fi” system",
                "An ethernet cable",
              ],
            },
          ],
        },
        {
          id: "h3",
          q: "STEP 2: CONNECT YOUR WHOLE HOME WI-FI SYSTEM",
          a: [
            {
              type: "step-p2",
              html1: "Once you have your equipment, set-up is a snap!",
              html2: [
                "Connect one end of your ethernet cable into the blue port (10GE_ETH1) of the Boingo Box mounted in your home, and the other end to your Whole Home Wi-Fi system’s base station (this may be called a “router” or “gateway” depending on the manufacturer).",
                "Plug in the base station to a power outlet.",
                "Now, follow the instructions that came with your equipment to set up your home Wi-Fi network.",
                "Finally, follow the instructions that came with your equipment to set up the additional Wi-Fi extenders (also called “satellites” or “pucks” depending on the manufacturer).",
              ],
            },
          ],
        },
        {
          id: 5,
          q: "Q. Where should I put my home Wi-Fi network?",
          a: [
            {
              type: "list",
              html: [
                "Ensure it is near your Boingo Box.",
                "For the best Wi-Fi signal coverage, and performance, it is recommended to place it away from obstructions.",
              ],
            },
          ],
        },
        {
          id: 6,
          q: "Q. How many devices can be put on a 1 GB home Wi-Fi system?",
          a: [
            {
              type: "p",
              html: "A 1GB Home Wi-Fi system can typically support a considerable number of devices simultaneously. If you plan to use many high bandwidth devices or intensive network activities, you may consider a router with more bandwidth.",
            },
          ],
        },
        {
          id: 7,
          q: "Q. Will Boingo be adding more plans and products to enhance the speed?",
          a: [
            {
              type: "p",
              html: "Yes, we are constantly working to provide the best service experience and committed to offering competitive speeds.  Stay tuned.",
            },
          ],
        },
        {
          id: 8,
          q: "Q. How do I connect devices to my home Wi-Fi system?",
          a: [
            {
              type: "list",
              html: [
                "Find your settings",
                "Go to Wi-Fi",
                "Choose the Wi-Fi network you created for your router",
                "Input password if applicable",
                "That’s it you’re on",
              ],
            },
          ],
        },
        {
          id: "h1",
          q: "STEP 3: 24/7/365 AWARD-WINNING CUSTOMER CARE",
          a: [
            {
              type: "support-p",
              link: "https://support.boingo.com/s/",
              tel: 8667264646,
              html1:
                "We get it. Not everyone is a Wi-Fi genius. If you get stuck setting up your equipment, we’re here to help! (We are, in fact, Wi-Fi geniuses.)",
              html2: "Boingo Support",
              html3: " has quick answers to our most common questions.",
              html4: "Our friendly and helpful agents are here 24/7/365 at ",
              html5: "866-726-4646",
              html6: ".",
            },
          ],
        },
      ],
    },
    {
      text: "What is a whole home Wi-Fi system (mesh Wi-Fi)?",
      array: [
        {
          id: 1,
          q: "Q. What is a whole home Wi-Fi system?",
          a: [
            {
              type: "p",
              html: "A whole home Wi-Fi, mesh Wi-Fi is an extensive wireless network created using specialized routers known as mesh routers.",
            },
          ],
        },
        {
          id: 2,
          q: "Q. How does a “whole home Wi-Fi system” work?",
          a: [
            {
              type: "p",
              html: "A mesh network blankets an entire home or office with consistent wireless signals.",
            },
          ],
        },
        {
          id: 3,
          q: "Q. What is a mesh router?",
          a: [
            {
              type: "p",
              html: "Mesh routers are routers used for creating whole home networks. A mesh router is one of two or more identical routers used to create a mesh network. It needs other mesh routers to maintain consistent high-speed Wi-Fi connection over a large area.",
            },
          ],
        },
        {
          id: 4,
          q: "Q. Do you recommend any specific whole home Wi-Fi systems?",
          a: [
            {
              type: "p",
              html: "If you have a router, you already love its BYO (bring your own router)! If you’re looking for recommendations, we’ve tested the below whole home Wi-Fi systems with great success:",
            },
            {
              type: "list",
              html: [
                "P-Link Deco W3600",
                "Amazon Eero 6",
                "D-Link AX1500",
                "GoogleAC1200",
              ],
            },
            {
              type: "p",
              html: "Please note, we do not endorse any of the products on this list or make any type of affiliate fee. There are many that meet our guidelines; feel free to use any of them.",
            },
          ],
        },
      ],
    },
    {
      text: "Troubleshooting Boingo Fiber to the home",
      array: [
        {
          id: 1,
          q: "Q. Are your cables connected properly?",
          a: [
            {
              type: "p",
              html: "Most connectivity issues are due to cables not being connected correctly.",
            },
          ],
        },
        {
          id: 2,
          q: "Q. Is your Boingo Box properly turned on?",
          a: [
            {
              type: "p",
              html: "Check the light on your Boingo Box (small white box with Boingo sticker), there should be two green lights flashing.  If these are solid, red, or off power off the device by pressing the orange button. Wait 30 seconds and release the orange power button.",
            },
          ],
        },
        {
          id: 3,
          q: "Check to see if there is a service interruption or maintenance in your area.",
          a: [
            {
              type: "link-p",
              link: "https://www.boingo.com/service-allbases/",
              linktext: "boingo.com/service-allbases",
              html1: "",
              html2: "",
            },
          ],
        },
        {
          id: 4,
          q: "Verify that your account is in good standing.",
          a: [],
        },
        {
          id: 5,
          q: "Restart your router by unplugging the power cord from the wall, waiting 30 seconds, and plugging it back in.",
          a: [
            {
              type: "p",
              html: "If powering off the device does not work, reset the device by taking a small object, like a paper clip, and inserting it in the pinhole, holding it in place for 30 seconds.",
            },
          ],
        },
        {
          id: 6,
          q: "Q. Is your router configured correctly for the Boingo network?",
          a: [
            {
              type: "p",
              html: "Note: there are many different types of routers and many ways to configure them. Here are some basic instructions to configure your router:",
            },
            {
              type: "list-bold-headers",
              html: [
                {
                  bold: "Connect the router to your modem: ",
                  text: "Use an Ethernet cable to connect the WAN or Internet port on the router to the Ethernet port on your fiber modem.",
                },
                {
                  bold: "Power on the router: ",
                  text: "Plug the power adapter into the router’s power port and connect it to a power outlet. The router should power on and start initializing.",
                },
                {
                  bold: "Connect to the router: ",
                  text: "On your computer or mobile device, open the list of available Wi-Fi networks. Locate the network name (SSID) of your router, which is usually written on a label attached to the router or provided in the router’s manual/box.",
                },
                {
                  bold: "Connect to the router’s Wi-Fi network: ",
                  text: "Select the network name (SSID) of your router and enter the corresponding password if prompted. This step allows your device to establish a wireless connection with the router.",
                },
                {
                  bold: "Configure the router: ",
                  text: "Open a web browser on your computer or mobile device and enter the router’s default IP address (e.g., 192.168.0.1 or 192.168.1.1) into the address bar. This will take you to the router’s administration page, where you can set up security settings, change the Wi-Fi network name or password, and perform other configurations if desired.",
                },
                {
                  bold: "Test the internet connection: ",
                  text: "Once the router is set up, check if you have an internet connection by browsing a website or running a speed test.",
                },
              ],
            },
          ],
        },
        {
          id: 7,
          q: "Recommended placement of the router",
          a: [
            {
              type: "list",
              html: [
                "Ensure your router is near your Boingo Box.",
                "For the best Wi-Fi signal coverage and performance, it is recommended to place your router away from any obstructions.",
              ],
            },
          ],
        },
        {
          id: 8,
          q: "Q. Do I need a mesh AP (Access Point) or Wi-Fi extender for better coverage?",
          a: [
            {
              type: "p",
              html: "No, a regular router will work. However, for the best experience, we recommend using a Whole Home Wi-Fi system or Wi-Fi extenders in larger areas and multiple floors.",
            },
          ],
        },
        {
          id: 9,
          q: "Still need help?",
          a: [
            {
              type: "link-p",
              link: "https://support.boingo.com/military/s/contactsupport,",
              linktext: "support.boingo.com/military/s/contactsupport",
              html1:
                "If you are still experiencing issues with your Boingo Fiber plan, reach out to Customer Care: ",
              html2: ".",
            },
          ],
        },
      ],
    },
    {
      text: "Add/remove a new router to the Boingo network",
      array: [
        {
          id: 1,
          q: "",
          a: [
            {
              type: "p",
              html: "Although all routers are slightly different, these steps will give you a general idea of the process.",
            },
          ],
        },
        {
          id: 2,
          q: "Add a new router",
          a: [
            {
              type: "list-ordered",
              html: [
                "Purchase a router. Select a router or combined router and modem. You can upgrade or replace a new router from your internet service provider.",
                "Place your router. Install your router in a centrally located area of your home. If possible, place your router in a higher position, like a shelf, and avoid potential interference from brick or concrete walls.",
                "Connect to power. Once you’ve plugged in your router and powered it on, the indicator lights should turn on. Lights may blink during the setup process but should show when the router properly turns on",
                "Activate Wi-Fi. If you’ve chosen a router that is separate from your modem, you’ll need to connect the two using a basic ethernet cable. Follow your router’s instructions on how to access the router’s web interface to connect to Wi-Fi.",
                "Create a new network name. Keeping the default network name can be a security risk as it gives hackers more insight into your network security. Update your wireless security by changing the admin name and password. Create a name that doesn’t include any personal information like names, addresses or birthdays",
                "Set a strong password. Remember to protect your Wi-Fi signal by creating a strong network password.",
                "Connect devices. To physically connect devices to your router, like a printer or game console, you’ll need an Ethernet cable for each. Once you’ve activated your Wi-Fi, you can connect your devices wirelessly by navigating to the settings of each device, selecting your network name and entering your password.",
              ],
            },
          ],
        },
        {
          id: 3,
          q: "Remove an existing router",
          a: [
            {
              type: "p",
              html: "Physically remove the cables from the old router. This includes the ethernet cable and the power cable.",
            },
          ],
        },
      ],
    },
    {
      text: "Can I use my existing router?",
      array: [
        {
          id: 1,
          q: "",
          a: [
            {
              type: "link-p",
              linktext: "boingo.com/fiber",
              link: "https://www.boingo.com/fiber",
              html1:
                "Unfortunately, the cable modem supplied by your service provider only works on their network. The Boingo Fiber network can work with most off-the-shelf routers available at the Exchange or any retailer. Check out our router recommendations at ",
              html2: ".",
            },
          ],
        },
      ],
    },
    {
      text: "How do I reactivate my account?",
      array: [
        {
          id: 1,
          q: "",
          a: [
            {
              type: "p",
              html: "Welcome back! Reactivating your account is easy. Here’s how:",
            },
            {
              type: "list-ordered-with-links",
              link: [
                "https://selfcare.boingohotspot.net/login",
                "https://selfcare.boingohotspot.net/accounthelp",
              ],
              html1: "Sign in to ",
              html2: "My Account",
              html3: ". If you forgot your username or password, reset it ",
              html4: "here",
              html5:
                "Select “Choose a New Plan” or “Reactivate My Planˮ at the top of the page and select the plan that works best for you.",
            },
            {
              type: "p",
              html: "Once your account is active, it’s time to get your devices online. Completely power cycle your Boingo Box and connect your router. When you power back up, your devices should connect automatically.",
            },
            {
              type: "p",
              html: "We accept the following forms of payment.",
            },
            {
              type: "img",
              src: creditcards,
            },
            {
              type: "p",
              html: "*Pre-paid cards, such as gift cards, are not an authorized payment method at this time",
            },
          ],
        },
      ],
    },
  ],
  helperText: {
    username:
      "Minimum 4 characters, and may only contain lowercase, numbers, and periods",
    password: "Password has to be between 6-16 characters",
  },
  errors: {
    description: 'There was a problem',
    errorHeader: "Error",
    default: "That page doesn't exist.",
  },
  nutritionFacts: {
    title: "Boingo Fiber",
    header: "Broadband Facts",
    description: `This monthly price is not an introductory rate.\n\nThis monthly price does not require a contract.`,
    subHeader1: "Boingo Wireless",
    subHeader2: "Basic 1 Mbps",
    subHeader3: "Mobile Broadband",
    subHeader4: "Fiber 1 Gig",
    subHeader5: ["MONTHLY PRICE:", "$69.00"],
    subHeader6: "Additional Charges & Terms",
    chargesText: [
      ["Provider Monthly Fees:", "$0"],
      ["One-Time Purchase Fees:", "$0"],
      ["Early Termination Fees:", "$0"],
      ["Government Taxes:", "$0"],
    ],
    subHeader7: ["Discounts & Bundles:", "None"],
    subHeader8: "Speeds Provided with Plan",
    speedsText: [
      ["Download:", "1 Gig"],
      ["Upload:", "1 Gig"],
      ["Typical Latency:", "34 ms"],
    ],
    subHeader9: ["Data Included with Monthly Price:", "Unlimited"],
    dataText: ["Charges for Additional\nData Usage:", "$0"],
    subHeader10: "Customer Support",
    customerSupport: "https://support.boingo.com/\nmilitary/s/boingo-for-military",
    supportPhone: "1.866.726.4646",
    footerText: `Learn more about the terms used on this label by visiting the Federal Communications Commission's Resource Center:`,
    link2: `https://www.fcc.gov/consumers`
  }
};
