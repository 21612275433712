import React, { useState, useCallback } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { content } from "../assets/const/content";
import "../assets/stylesheets/nutritionFacts.css";

export const NutritionFacts = ({ open, onClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Modal
      className="modal"
      open={open}
      onClose={onClose}
      aria-labelledby="nutrition-facts-modal-title"
      aria-describedby="nutrition-facts-modal-description"
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          backdropFilter: "blur(1px)",  
        }
      }}
    >
      <Box className="modal-wrapper"
        sx={{
          '& .MuiTypography-root': {
            lineHeight: 1.55, 
          },
          '&, .MuiModal-root:focus': {
            outline: 'none', 
          },
        }}
      >
        <Box className="icon-wrapper">
          <CloseIcon onClick={onClose} className="close-icon" />
        </Box>
        <Box className="content-wrapper">
          <Typography variant="h1" className="modal-title flex-container">
            {content.nutritionFacts.title}
          </Typography>
          <Box className="modal-content">
            <Typography variant="h1" className="modal-header">
              {content.nutritionFacts.header}
            </Typography>
            <hr className="hr-thick" />
            <Box>
              <Typography variant="h2">{content.nutritionFacts.subHeader1}</Typography>
              <Typography variant="h2">{content.nutritionFacts.subHeader4}</Typography>
              <hr  className="hr-thick" />
              <Box className="flex-sb">
                <Typography variant="h2">
                  {content.nutritionFacts.subHeader5[0]}
                </Typography>
                <Typography variant="h2">
                  {content.nutritionFacts.subHeader5[1]}
                </Typography>
              </Box>
            </Box>

            <hr className="hr-thick" />
            <Typography style={{ whiteSpace: 'pre-line' }}>
              {content.nutritionFacts.description}
            </Typography>
            <hr />

            <Typography variant="h2">
              {content.nutritionFacts.subHeader6}
            </Typography>
            <>
              {content.nutritionFacts.chargesText.map((item) => (
                <Box className="flex-sb">
                  <Typography>{item[0]}</Typography>
                  <Typography fontFamily="Figtree-ExtraBold">{item[1]}</Typography>
                </Box>
              ))}
            </>
            <hr className="hr-thick" />
            <Box className="flex-sb">
              <Typography variant="h2">
                {content.nutritionFacts.subHeader7[0]}
              </Typography>
              <Typography variant="h2">
                {content.nutritionFacts.subHeader7[1]}
              </Typography>
            </Box>
            <hr />
            <Typography  variant="h2">
              {content.nutritionFacts.subHeader8}
            </Typography>
            <>
              {content.nutritionFacts.speedsText.map((item) => (
                <Box className="flex-sb">
                  <Typography>{item[0]}</Typography>
                  <Typography fontFamily="Figtree-ExtraBold">{item[1]}</Typography>
                </Box>
              ))}
            </>
            <Box className="flex-sb" mt={2}>
              <Typography variant="h2">
                {content.nutritionFacts.subHeader9[0]}
              </Typography>
              <Typography variant="h2">
                {content.nutritionFacts.subHeader9[1]}
              </Typography>
            </Box>
            <Box 
              sx={{ whiteSpace: "pre-line", }} 
              className="flex-sb">
              <Typography>{content.nutritionFacts.dataText[0]}</Typography>
              <Typography fontFamily="Figtree-ExtraBold">{content.nutritionFacts.dataText[1]}</Typography>
            </Box>
            <hr className="hr-thick" />

            <Box>
            <Typography variant="h2">
              {content.nutritionFacts.subHeader10}
            </Typography>
            <a href={content.nutritionFacts.customerSupport}>
              {content.nutritionFacts.customerSupport}
            </a>
            <br />
            <a href="tel:18667264646">
              {content.nutritionFacts.supportPhone}
            </a>
            </Box>
            <hr style={{ margin: "4px auto" }} />
            <Box pb={2}>
                <Typography>
                  {content.nutritionFacts.footerText}
                  <a href={`${content.nutritionFacts.link2}`}>
                    {content.nutritionFacts.link2}
                  </a>
                </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};