import React, { useState, useEffect, useRef, useDebugValue } from "react";
import {
  getDeviceList,
  updateDeviceList,
  addDeviceList,
} from "../services/apiServices";
import {
  Box,
  Typography,
  List,
  ListItem,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";

import { getQueryParam } from "../config/utils";

import GetUserOnline from "../components/GetUserOnline";
import { ETHERNET_PLAN_TYPE } from "../assets/const/constant";
import { HomeRouterModal } from "./HomeRouterModal";

const DeviceModelSelector = ({ onDeviceAdd }) => {
  const [deviceModel, setDeviceModel] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const [macAddress, setMacAddress] = useState(getQueryParam("mac") || "");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(
      "device model",
      deviceModel,
      "device name",
      deviceName,
      "MAC",
      macAddress
    );
    onDeviceAdd({
      model: deviceModel,
      name: deviceName,
      mac: macAddress,
    });
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Typography variant="h6" sx={{ mb: 3 }}>
        Add a new device
      </Typography>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>Device Type</InputLabel>
        <Select
          value={deviceModel}
          label="Device Type"
          onChange={(e) => setDeviceModel(e.target.value)}
          required
          sx={{ textAlign: "left" }}
        >
          <MenuItem value="Smart Phone">
            <span className="deviceModel Smart Phone"></span>Phone
          </MenuItem>
          <MenuItem value="Tablet">
            <span className="deviceModel Tablet"></span>Tablet
          </MenuItem>
          <MenuItem value="Computer">
            <span className="deviceModel Computer"></span>Computer
          </MenuItem>
          <MenuItem value="Gaming">
            <span className="deviceModel Gaming"></span>Game Console
          </MenuItem>
          <MenuItem value="TV">
            <span className="deviceModel TV"></span>TV
          </MenuItem>
          <MenuItem value="Smart Device">
            <span className="deviceModel Device"></span>Other
          </MenuItem>
        </Select>
      </FormControl>
      <TextField
        fullWidth
        label="Device Name"
        value={deviceName}
        onChange={(e) => setDeviceName(e.target.value)}
        required
        sx={{ mb: 2 }}
      />
      <Button
        type="submit"
        variant="contained"
        sx={{ fontSize: "16px", boxShadow: "none", mt: 3 }}
      >
        Add Device
      </Button>
    </Box>
  );
};

export const DeviceIDFlow = ({ 
  loginData, 
  onSuccess, 
  onError, 
  closeModal, 
  setShowDeviceIDFlow
}) => {
  const [deviceList, setDeviceList] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [routerError, setRouterError] = useState(null);
  const [showGetUserOnline, setShowGetUserOnline] = useState(false);
  const [showDeviceModelSelector, setShowDeviceModelSelector] = useState(false);
  const [showHomeRouterModal, setShowHomeRouterModal] = useState(false);
  const [modalText, setModalText] = useState("Select the device you would like to replace");
  const [macAddress, setMacAddress] = useState(getQueryParam("mac") || "");
  const [homeRouter, setHomeRouter] = useState(null);
  const [deviceMac,] = useState(getQueryParam("mac") || "");

  const fetchedRef = useRef(false);

  const onClose = () => {
    closeModal();
  };

  const fetchDeviceList = async () => {
    if (fetchedRef.current) return; // If already fetched, don't fetch again
    fetchedRef.current = true; // Mark as fetched

    try {
      setIsLoading(true);
      const response = await getDeviceList(loginData);
      if (response.data.devices && response.data.devices.length > 0) {
        // plan type is BROADBAND_FIBER
        if (response?.data?.planType && response?.data?.planType === ETHERNET_PLAN_TYPE) {
          const homeRouter = response?.data?.devices?.find(item => item.name === "Home Router") || null;

          // if no home router, make oldest device Home Router
          if (!homeRouter) {
            const earliestTimestamp = Math.min(...response?.data?.devices?.map(item => new Date(item.created_date).getTime()));
            const newHomeRouter = response?.data?.devices?.find(item => new Date(item.created_date).getTime() === earliestTimestamp);
            setHomeRouter({...newHomeRouter, name: "Home Router"});
          } else {
            setHomeRouter(homeRouter);
          }
  
          const withoutHomeRouterList = response?.data?.devices?.filter(item => item.name !== "Home Router") || '';
          withoutHomeRouterList?.length && setDeviceList(withoutHomeRouterList);
          setShowHomeRouterModal(true);
        } else {
          setDeviceList(response.data.devices);
        }
        setShowDeviceModelSelector(false);
      } else {
        setShowDeviceModelSelector(true);
      }
      setIsLoading(false);
    } catch (err) {
      console.error("Error fetching device list:", err);
      setError("Failed to fetch device list. Please try again.");
      setIsLoading(false);
      const errorMsg = err.response?.data ? err.response?.data?.message : err.message;
      if (typeof onError === "function") {
        onError(errorMsg);
      }
    }
  };

  const handleReplaceHomeRouter = async () => {
    if (!homeRouter) {
      onError('No Home Router');
    } else {
      updateDeviceList(homeRouter.device_id, {
        ...homeRouter,
        mac: deviceMac,
      })
        .then((res) => {
          onSuccess(null);
        })
        .catch((err) => {
          const errorMsg = err.response?.data ? err.response?.data?.message : err.message;
          if (err?.response?.status === 409 && err.response.data.code === 100) {
            setRouterError("You'll need to reboot the router first. After the reboot, you'll be able to get online and you'll be ready to experience life in the fast lane.");
          } else {
            onError(errorMsg || "There was a problem");
          }
        });
    }
  };

  useEffect(() => {
    fetchDeviceList();
  }, [loginData, onError]);

  const handleDeviceSelect = async (device) => {
    setSelectedDevice(device);
    if (!macAddress) {
      console.error("MAC address is missing");
      if (typeof onError === "function") {
        onError("MAC address is missing. Please try again or contact support.");
      }
      return;
    }

    //always use mac address from query params to update
    let deviceMac = {
      mac: macAddress,
    };

    try {
      const response = await updateDeviceList(device.device_id, deviceMac);
      if (response.status === 200) {
        console.log("Device updated successfully");
        setShowGetUserOnline(true);
      } else {
        console.error("Failed to update device");
        if (typeof onError === "function") {
          onError("Failed to update device. Please try again.");
        }
      }
    } catch (err) {
      console.error("Error updating device:", err);
      if (typeof onError === "function") {
        const errorMsg = err.response?.data ? err.response?.data?.message : err.message;
        onError(errorMsg);
      }
    }
  };

  const handleDeviceAdd = async (newDevice) => {
    try {
      if (!newDevice.mac) {
        throw new Error("MAC address needs to be defined");
      }
      //
      const response = await addDeviceList(newDevice);
      if (response.status === 201) {
        console.log("Device added successfully");
        setShowGetUserOnline(true);
        //await fetchDeviceList();
      } else {
        console.error("Failed to add device");
        if (typeof onError === "function") {
          onError("Failed to add device. Please try again.");
        }
      }
    } catch (err) {
      console.error("Error adding device:", err);
      if (typeof onError === "function") {
        const errorMsg = err.response?.data ? err.response?.data?.message : err.message;
        onError(errorMsg);
      }
    }
  };

  const handleGetUserOnlineSuccess = (data) => {
    onSuccess({ ...loginData, selectedDevice: selectedDevice, ...data });
  };

  const handleGetUserOnlineError = (error) => {
    if (typeof onError === "function") {
      onError(error);
    }
  };

  const handleConfirmUserChoice = (userChoice) => {
    setShowHomeRouterModal(false);
    setIsLoading(false);
    
    if (userChoice === 'no' && !deviceList.length) {
      setShowDeviceModelSelector(true);
    } 
    else if (userChoice !== "no") {
      closeModal();
      setShowDeviceIDFlow(false);
      setShowDeviceModelSelector(false);
    }
  };


  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (showGetUserOnline) {
    return (
      <GetUserOnline
        customerDataObj={loginData}
        onSuccess={handleGetUserOnlineSuccess}
        onError={handleGetUserOnlineError}
      />
    );
  }

  if (showDeviceModelSelector) {
    return <DeviceModelSelector onDeviceAdd={handleDeviceAdd} />;
  }

  if (showHomeRouterModal) {
    return <HomeRouterModal 
      open={showHomeRouterModal} 
      onClose={handleConfirmUserChoice} 
      onReplaceHomeRouter={handleReplaceHomeRouter}
      error={routerError}
    />
  }

  return (
    <Box>
      <Typography variant="h6" sx={{ mb: 2, lineHeight: 1 }}>
        {modalText}
      </Typography>
      <List>
        {deviceList.map((device) => (
          <ListItem
            key={device.id || device.name}
            onClick={() => handleDeviceSelect(device)}
            sx={{
              cursor: "pointer",
              border: "1px solid #ccc",
              borderRadius: "4px",
              padding: "7px 10px",
              margin: "8px 0",
              "&:hover": {
                backgroundColor: "#f0f0f0",
              },
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box sx={{ mr: 1 }}>
              <Box className={`deviceModel ${device.model}`}></Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="body1" sx={{ fontFamily: "Figtree-Bold" }}>
                {device.name || `Device ${device.id}`}
              </Typography>
              <Typography variant="body2" sx={{ color: "#ccc" }}>
                {device.mac}
              </Typography>
            </Box>
          </ListItem>
        ))}
      </List>
      <Typography
        onClick={onClose}
        variant="body2"
        sx={{ textDecoration: "underline", cursor: "pointer" }}
      >
        Cancel
      </Typography>
    </Box>
  );
};
