import React, { useState, useEffect, useCallback } from "react";
import { content } from "../assets/const/content";
import {
  Box,
  TableFooter,
  Typography,
  Popover,
  List,
  ListItemText,
  Button,
  Grid,
  AppBar, 
  Drawer,
  Toolbar,
  Container,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// import { ReactComponent as Router } from "../assets/images/Router.svg";
import Router from '../assets/images/Router.png'
import { ReactComponent as EthernetCable } from "../assets/images/Ethernet-Cable-Icon.svg";
import { ReactComponent as WhatYoullNeed } from "../assets/images/what-youll-need-subhead.svg";
import { ReactComponent as NeedHelp } from "../assets/images/need-help-subhead.svg";
import TPLinkAX10 from "../assets/images/TP-Link-AX-10.jpg"
import TPLinkAX21 from "../assets/images/TP-Link-AX-21.jpg"
import ASUSAX1800 from "../assets/images/ASUS-AX-1800.jpg"
import LinkSysMR7350 from "../assets/images/Linksys-MR-7350.jpg"
import fiberLogo from "../assets/images/Boingo-Fiber-Logo.svg";
import signInIcon from "../assets/images/Sign-In-Mobile.svg";
import boingoLogo from "../assets/images/Boingo-Logo.svg";
import vectorCheck from "../assets/images/vector_checkai.png";
import setUpSubHeader from "../assets/images/setup-in-seconds-subhead.svg";
import { ReactComponent as Setup } from "../assets/images/Set-Up.svg";
import { ReactComponent as NumberOne } from "../assets/images/Number-1-Red.svg";
import { ReactComponent as NumberTwo } from "../assets/images/Number-2-Red.svg";
import { ReactComponent as NumberThree } from "../assets/images/Number-3-Red.svg";

import { LoginModal } from "../components/LoginModal";
import Header from "../components/Header";
import SetupSteps from "../components/SetupSteps";
import WelcomeFooter from "../components/WelcomeFooter";
import LinkWithQuery from "../components/LinkWithQuery";
import { getSelfcareUrl } from "../config/config";
import "../assets/stylesheets/welcome.css";
import { Helmet } from "react-helmet";

const Navbar = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box>
      <Box className="mobile-nav-container" onClick={handleDrawerToggle}>
        <img src={fiberLogo} />
        <CloseIcon className="close-icon" />
      </Box>

      <Box className="mobile-nav-sub-section">
        <Typography className="lets-start">
        </Typography>
        <LinkWithQuery to="/signup-form" className="avail-btn">
          Sign Up Now
        </LinkWithQuery>
      </Box>
      <Box className="mobile-nav-hr"></Box>
      <Box className="mobile-sign-in-wrapper">
        <a href={getSelfcareUrl(window.location.host)}>
          <img src={signInIcon} />
          Sign In
        </a>
      </Box>
    </Box>
  );

  const [loginModalOpen, setLoginModalOpen] = useState(false);

  const handleLoginClick = () => {
    setLoginModalOpen(true);
  };

  const handleLoginClose = () => {
    setLoginModalOpen(false);
  };

  const handleLoginSuccess = useCallback((data) => {
    console.log("Authentication successful", data);
    setLoginModalOpen(true); // Close the modal

    // Use setTimeout to delay the navigation
    setTimeout(() => {
      const currentHash = window.location.hash.split("?")[0]; // Keep the '#' but remove query params
      const basePath = currentHash.split("/")[1]; // Get the second part of the path (e.g., 'afmthm')
      const newPath = `#/${basePath}/welcome`;
      window.location.hash = newPath;
    }, 2000);
  }, []);

  return (
    <>
      <AppBar
        position="fixed"
        className="app-bar"
        sx={{ backgroundColor: "#fff" }}
      >
        <Toolbar>
          {isMobile ? (
            <>
              {/* Mobile Navigation */}
              <Grid 
                container 
                alignItems="center" 
                justifyContent="center" 
                className="mobile-nav"
                sx={{width: "90%"}}
              >
                <Grid item xs={3} sx={{ textAlign: "left" }}>
                  <LinkWithQuery to="/" className="fiber-plan-link">
                    <img
                      src={boingoLogo}
                      className="boingo-logo"
                      alt="Boingo Logo"
                    />
                  </LinkWithQuery>
                </Grid>
                <Grid item xs={6} sx={{alignSelf: 'center'}}>
                </Grid>

                <Grid item xs={3} sx={{ textAlign: "right" }}>
                  <a
                    className="my-account-link"
                    sx={{ color: "#0c93b3", }}
                  >
                    Account
                  </a>
                </Grid>
              </Grid>
            </>
          ) : (
            <Container
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                flexFlow: "row",
                "&.MuiContainer-root": { maxWidth: '1500px' }
              }}
            >
              <Grid
                sx={{
                  width: "auto",
                  alignSelf: "flex-start",
                }}
              >
                <Grid
                  item
                  sx={{
                    textAlign: "right",
                    width: "300px",
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <LinkWithQuery to="/" className="fiber-plan-link">
                    <img
                      src={boingoLogo}
                      className="boingo-logo"
                      alt="Boingo Logo"
                    />
                  </LinkWithQuery>
                </Grid>
              </Grid>
              <Grid sx={{ textAlign: "right" }}>
              </Grid>
              <Grid
                sx={{
                  justifyContent: "flex-end",
                  display: "flex",
                }}
              >
                <Grid
                  sx={{
                    display: "flex",
                    width: "300px",
                    flexFlow: "row wrap",
                    alignSelf: "flex-end",
                    justifyContent: "flex-end",
                  }}
                >
                  <a
                    className="my-account-link"
                    href="https://selfcare.boingohotspot.net/login"
                  >
                    Log in to My Account
                  </a>
                </Grid>
              </Grid>
            </Container>
          )}
        </Toolbar>
      </AppBar>
      <Toolbar /> {/* Offset for content */}
      {/* Login Modal */}
      <LoginModal
        open={loginModalOpen}
        onClose={handleLoginClose}
        onLoginSuccess={handleLoginSuccess}
      />
    </>
  );
};

// RouterSpecsSection component
const RouterSpecsSection = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handlePopOverClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Box 
        sx={{
          '& .MuiTypography-root': {
            fontSize: '16px', 
          },
        }}
      className="welcome-router-section" id="routerSection">
        <Container
          sx={{
            '&.MuiContainer-root': {
              padding: "0",
              margin: "0",
            }
          }}
        >
          <Box id="SetupInSeconds" sx={{ padding: 0, margin: 0}} className="router-header-img">
            <img src={setUpSubHeader} />
          </Box>

          <Typography sx={{ 
            fontSize: "16px", 
            alignContent: "center", 
            '&.MuiTypography-root': {
              padding: 0,
              margin: 0,
            },

          }}  className="router-desc">
            Set-up is as easy as plugging in your equipment.
          </Typography>
          <Box 
            sx={{ scale: "0.9" }} 
            className="router-img-section">
              <Setup />
          </Box>

          <Box class="steps-container">
            <Box class="step">
              <Box class="step-img">
                <NumberOne />
              </Box>
              <Typography class="step-content">{content.routerSteps.one}</Typography>
            </Box>
            <Box class="step">
              <Box class="step-img">
                  <NumberTwo />
              </Box>
              <Typography class="step-content">{content.routerSteps.two}</Typography>
            </Box>  
            <Box class="step">
              <Box class="step-img">
                  <NumberThree />
              </Box>
              <Typography class="step-content">{content.routerSteps.three}</Typography>
            </Box>
          </Box>

        </Container>
      </Box>
    </>
  );
};


export default function Welcome() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Boingo Ethernet - Welcome</title>
      </Helmet>
      <div className="fiber-welcome-container">
        <Navbar />
        <div id="welcome-header" sx={{ width: "" }}>
          <Box className="welcome-to">{content.welcomeWelcomeTo}</Box>
          <Header />
        </div>
        <Box className="container welcome">
          <Box className="wrapper welcome">
            <Typography variant="h5" sx={{ fontFamily: "Figtree-Regular" }}>
              {content.welcomeSubhead1}
            </Typography>
          </Box>
        </Box>
        <Box className="what-youll-need-container">
          <Box className="what-youll-need-wrapper">
            <Box className="welcome-subhead-svg gw-item what-youll-need">
              <Box>
                <WhatYoullNeed />
              </Box>
            </Box>
            <Box
              className="gw-item wifi what-youll-need-images"
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "60%",
                marginX: "30px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  fontSize: "12px",
                  width: "100%",
                  margin: "10px auto",
                }}
              >
                <Box sx={{}}>
                  <img
                    style={{
                      maxHeight: "100px",
                      width: "auto",
                      marginBottom: "30px",
                    }}
                    src={Router}
                  />
                </Box>
                <Typography fontFamily={["Figtree-Bold"]}>Router</Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  fontSize: "12px",
                  width: "100%",
                  margin: "10px auto",
                }}
              >
                <Box sx={{}}>
                  <EthernetCable
                    style={{
                      minHeight: "100px",
                      width: "auto",
                      marginBottom: "30px",
                    }}
                  />
                </Box>
                <Typography fontFamily={["Figtree-Bold"]}>
                  An ethernet cable
                </Typography>
              </Box>
            </Box>
            <Box sx={{}}>
              <Box className="gw-item additional">
                <p>
                  We have stress tested these systems in our labs. You can
                  purchase them{!isMobile && <br />}online or at the Exchange.
                </p>
              </Box>

              <Box
                className="router-images"
                sx={{ display: "flex", textAlign: "center" }}
              >
                <Box sx={{}}>
                  <img
                    src={TPLinkAX10}
                    style={{ width: "200px", margin: "auto 12px" }}
                  />
                  <Typography fontFamily={"Figtree-Bold"}>
                    TP-Link AX10
                  </Typography>
                </Box>
                <Box sx={{}}>
                  <img
                    src={TPLinkAX21}
                    style={{ width: "200px", margin: "auto 12px" }}
                  />
                  <Typography fontFamily={"Figtree-Bold"}>
                    TP-Link AX21
                  </Typography>
                </Box>
                <Box sx={{}}>
                  <img
                    src={ASUSAX1800}
                    style={{ width: "200px", margin: "auto 12px" }}
                  />
                  <Typography fontFamily={"Figtree-Bold"}>
                    ASUS AX1800
                  </Typography>
                </Box>
                <Box sx={{}}>
                  <img
                    src={LinkSysMR7350}
                    style={{ width: "200px", margin: "auto 12px" }}
                  />
                  <Typography fontFamily={"Figtree-Bold"}>
                    Linksys MR7350
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  margin: isMobile ? "0" : "48px auto 0",
                  textAlign: "center",
                  width: isMobile ? "100%" : "75%",
                  textAlign: "center",
                  lineHeight: "1.1",
                  fontSize: "10px",
                }}
              >
                <Typography
                  sx={{ margin: isMobile ? "30px 0 18px 0" : "0 0 18px 0" }}
                >
                  Have a router you already love? Just make sure it meets these
                  specs:
                </Typography>
                <List
                  sx={{
                    justifySelf: "center",
                    listStyleType: "disc",
                    margin: "-10px 0 0px 14px",
                    textAlign: "left",
                    margin: isMobile && "20px",
                  }}
                >
                  {[
                    "Supports gigabit wired speeds",
                    "Wi-Fi 5 (802.11ac) or Wi-Fi 6 (802.11ax)",
                    "80Mhz channel width",
                    "Minimum WPA2 Security",
                  ].map((text, index) => (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img className="check" src={vectorCheck} />
                      <ListItemText
                        primary={text}
                        key={index}
                        sx={{
                          "& .MuiTypography-root": {
                            fontSize: "14px",
                            lineHeight: "1.2",
                            color: "#3d434a",
                          },
                        }}
                      />
                    </Box>
                  ))}
                </List>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className="setup-in-seconds-container">
          <Box className="setup-in-seconds-wrapper">
            <RouterSpecsSection />
          </Box>
        </Box>

        <Box className="here-to-help-container">
          <Box className="here-to-help-wrapper">
            <Box className="welcome-subhead-svg">
              <Box sx={{}} className="welcome-subhead-svg-img">
                <NeedHelp />
              </Box>
              <Box className="here-to-help-wrapper">
                <Box className="welcome-subhead-svg here-to-help">
                  <Box className="helpSubhead1" sx={{ textAlign: "center" }}>
                    <Typography>
                      {content.welcomeHelpSubhead1}
                      {!isMobile ? <br /> : ""}{" "}
                      <a target="_blank" href="https://support.boingo.com/s/">
                        {content.welcomeHelpSubhead2}
                      </a>
                      {content.welcomeHelpSubhead3}
                    </Typography>
                  </Box>
                </Box>
                <Box className="here-to-help-img"></Box>
                <Button
                  disableElevation
                  sx={{
                    mb: !isMobile ? "50px" : "24px",
                    "&.MuiButton-root": { mt: isMobile ? "24px" : "36px" },
                  }}
                  variant="contained"
                  type="submit"
                  className="welcome-faq-button"
                >
                  <a href="https://support.boingo.com/BarracksAndDormsFiber/s/">
                    see FAQs
                  </a>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <WelcomeFooter />
      </div>
    </>
  );
}
