import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  Box,
  Container,
  Select,
  TextField,
  Typography,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  Table,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";

//utils
import { getVenueDataFromVenueId } from "../config/utils";

export default function ProductSection({
  finalCustomerInfo,
  setFinalCustomerInfo,
}) {
  const { venueId } = useParams();
  const venueData = getVenueDataFromVenueId(venueId);

  const [discountInfo, setDiscountInfo] = useState("");
  const showDiscountWindow =
    !discountInfo && !venueData.promo && !venueData.promo?.promo_price
      ? false
      : true;

  useEffect(() => {
    if (finalCustomerInfo && finalCustomerInfo.promo_code_success) {
      setDiscountInfo(finalCustomerInfo.promo_code_success);
    }
  }, [finalCustomerInfo]);
  return (
    <>
      {/* {JSON.stringify(finalCustomerInfo)} */}
      <Box sx={{ backgroundColor: "#e0edf5" }}>
        <TableContainer>
          <Table
            className="table-wrapper"
            sx={{
              width: { xs: "90%", md: "450px", lg: "750px" },
              margin: "auto",
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  className="product-title"
                  sx={{
                    padding: "15px 0 0 0",
                    borderBottom: "0px",
                  }}
                >
                  Boingo Fiber
                </TableCell>

                <TableCell
                  className="product-title"
                  sx={{ padding: "15px 0 0 0", borderBottom: "0px" }}
                  align="right"
                >
                  {/* Use the promo info if available, otherwise fall back to const file */}
                  {discountInfo ? (
                    <span style={{ textDecoration: "line-through", marginRight: "6px", }}>
                      ${discountInfo.original_price}
                    </span>
                  ) 
                  : venueData.promo && venueData.promo.promo_price ?
                    <span style={{ textDecoration: "line-through", marginRight: "6px", }}>
                      ${venueData.plan_price}
                    </span>
                  : ("")} 
                  $
                  {discountInfo
                    ? discountInfo.final_price.toFixed(2)
                    : venueData.plan_price.toFixed(2)}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  className="description"
                  sx={{ padding: "0 0 15px 0", borderBottom: "0px" }}
                >
                  Up to 1 Gig per second
                </TableCell>
                <TableCell
                  className="description"
                  sx={{ padding: "0 0 15px 0", borderBottom: "0px" }}
                  align="right"
                >
                  {showDiscountWindow ? "first month" : "per month"}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}
