import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
} from "@mui/material";
import "../assets/stylesheets/modal.css";

export const HomeRouterModal = ({ open, onClose, onReplaceHomeRouter, error }) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="home-router-modal-title"
      aria-describedby="home-router-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "35%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 300,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          border: 0,
          textAlign: "center",
        }}
      >
        <Typography sx={{ fontSize: "1.5rem", fontFamily: "Museo900" }}>
          Do you want to replace your router?
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "80%",
            margin: "0 auto",
          }}
        >
          <Button
            type="contained"
            onClick={(e) => { onReplaceHomeRouter(); }}
            sx={{
              backgroundColor: "#35c0e2",
              color: "#FFF",
              fontSize: "1.5rem",
              fontFamily: "Museo900 !important",
              my: 3,
              "&:hover": {
                opacity: "80%",
              },
            }}
            disabled={isLoading || error}
          >
            {isLoading ? <CircularProgress size={24} color="inherit" /> :"Yes" }
          </Button>
          <Button
            type="outlined"
            onClick={() => {
              onClose("no");
            }}
            sx={{
              color: "#35c0e2",
              backgroundColor: "#FFF",
              border: "2px solid #35c0e2",
              fontSize: "1.5rem",
              fontFamily: "Museo900",
              mb: 3,
              "&:hover": {
                opacity: "80%",
                color: "#FFF"
              },
            }}
            disabled={isLoading || error}
          >
            {isLoading ? <CircularProgress size={24} color="inherit" /> : "No"}
          </Button>
          {error && (
            <Typography
              color="error"
              sx={{ fontSize: "14px" }}
            >
              {error}
            </Typography>
          )}
        </Box>
      </Box>
    </Modal>
  );
};
