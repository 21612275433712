import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box } from "@mui/material";
import { authenticateUser } from "../services/apiServices";
import { useNavigate, useParams } from "react-router-dom";
import { getQueryParam } from "../config/utils";
import "../assets/stylesheets/modal.css";


const GetUserOnline = ({ customerDataObj, onSuccess, onError }) => {
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const navigate = useNavigate();
  const { venueId }= useParams();

  // Perform an alive check to see if user is already online (on open internet, for example).
  // If user is online, bypass the network controller login.
  const getAliveCheck = async () => {
    try {
      const response = await axios({
        method: "GET",
        url: "https://alive.boingohotspot.net/cgi-bin/alive?1234567",
        timeout: 5000,
      });
      console.log("Alive check response:", response.data);
      return response.data === 1524155677480;
    } catch (error) {
      console.log("Alive check error:", error);
      return false;
    }
  };

  useEffect(() => {
    const performAuthentication = async () => {
      setIsAuthenticating(true);
      try {
        const isAlive = await getAliveCheck();
        console.log("Is alive:", isAlive);

        if (isAlive) {
          console.log("User is already online, calling onSuccess");
          onSuccess(customerDataObj);
        } else {
          console.log("User is not online, calling authenticate");
          await authenticate();
        }
      } catch (err) {
        console.log("Error during authentication process:", err);
        const errorMsg = err.response?.data ? err.response?.data?.message : err.message;
        onError(errorMsg || "An error occurred during authentication");
      } finally {
        setIsAuthenticating(false);
      }
    };

    if (customerDataObj) {
      performAuthentication();
    }
  }, [customerDataObj, onSuccess, onError]);

  const authenticate = async () => {
    console.log("Authenticate function called");
    try {
      const { username, password, GSID } = customerDataObj;
      const appendBoingoToUsername = `boingo/bw!${username}`;

      // Parse query string
      const queryParams = {
        gsid: getQueryParam("gsid"),
        mac: getQueryParam("mac"),
        link_login_only: getQueryParam("link_login_only"),
      };

      const controllerData = {
        username: appendBoingoToUsername,
        password,
        gsid: GSID || queryParams.gsid,
        mac: queryParams.mac,
        link_login_only: queryParams.link_login_only,
      };

      // Check for missing required fields
      const missingFields = Object.entries(controllerData)
        .filter(([key, value]) => !value && key !== "password")
        .map(([key]) => key);

      if (missingFields.length > 0) {
        throw new Error(`Missing required fields: ${missingFields.join(", ")}`);
      }

      await createVirtualFormAndSubmit(controllerData);
    } catch (error) {
      // redirect to error page if controller returns redirect query param
      const redirect = getQueryParam("redirect");
      if (redirect) {
        const currentHash = window.location.hash.split("?")[0];
        const basePath = currentHash.split("/")[1]; 
        const newPath = `#/${basePath}${redirect}`; 
        window.location.hash = newPath;
      }

      console.log("Error during authentication:", error);
      onError(error.message || "An error occurred during authentication");
    }
  };

  const createVirtualFormAndSubmit = async (dataFromController) => {
    // Create a virtual form element
    const form = document.createElement("form");
    form.className = "hiddenForm";
    form.id = "via_login_form";
    form.method = "POST";
    form.style.display = "none";

    // Define the input fields to be included in the form
    const inputs = [
      { id: "username", name: "username" },
      { id: "user", name: "user" },
      { id: "password", name: "password" },
      { id: "cmd", name: "cmd", value: "authenticate" },
      { id: "buttonClicked", name: "buttonClicked", value: "4" },
      { id: "gsid", name: "gsid" },
    ];

    // Create and append input elements to the form
    inputs.forEach((input) => {
      const inputElement = document.createElement("input");
      inputElement.type = "hidden";
      inputElement.style.display = "none";
      inputElement.className = "noshow";
      inputElement.id = input.id;
      inputElement.name = input.name;
      inputElement.value = input.value || "";
      form.appendChild(inputElement);
    });

    // Append the form to the body
    document.body.appendChild(form);

    // Fill in the form data using the provided dataFromController object
    Object.entries(dataFromController).forEach(([key, value]) => {
      if (key === "link_login_only") {
        // Set the form's action attribute
        form.action = value;
      } else if (key === "username" || key === "user") {
        // Set both 'username' and 'user' fields to the same value
        form.querySelector("#username").value = value;
        form.querySelector("#user").value = value;
      } else {
        // Find the input with the matching ID and set its value
        const input = form.querySelector("#" + key);
        if (input) {
          input.value = value;
        }
      }
    });

    // Submit the form
    form.submit();
  };

  if (isAuthenticating) {
    return (
      <Box sx={{ visibility: "hidden", display: "none" }}>
        Authenticating...
      </Box>
    );
  }

  return null;
};

export default GetUserOnline;
