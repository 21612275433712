import React, { useEffect, useState, useCallback } from "react";
import { useLocation, Link, useParams } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { getSelfcareUrl } from "../config/config";
import LinkWithQuery from "../components/LinkWithQuery";
import { useForm, Controller } from "react-hook-form";

import {
  Grid,
  Box,
  Container,
  Button,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useTheme,
  useMediaQuery,
  Popover,
  Modal,
  TextField,
  FormHelperText,
  CircularProgress,
} from "@mui/material";

//utils
import { getVenueDataFromVenueId } from "../config/utils";

import CloseIcon from "@mui/icons-material/Close";
import "../assets/stylesheets/landing.css";
import boingoLogo from "../assets/images/Boingo-Logo.svg";
import fiberLogo from "../assets/images/Boingo-Fiber-Logo.svg";
import whyBoingoFiberSubHeader from "../assets/images/why-boingo-fiber-subhead.svg";
import crossedPrice from "../assets/images/crossed-out-price.svg";

import vectorCheck from "../assets/images/vector_checkai.png";
import vectorLeftWhisker from "../assets/images/vector_left_whisker.png";
import vectorRightWhisker from "../assets/images/vector_right_whisker.png";

import signInIcon from "../assets/images/Sign-In-Mobile.svg";
import setUpSubHeader from "../assets/images/setup-in-seconds-savings-subhead.svg";

import worksWithBoingImg from "../assets/images/Works-With-Boingo-Fiber-Sticker.svg";
import horizontalLine from "../assets/images/Footer-Horizontal-Line-Desktop.svg";
import lookForSticker from "../assets/images/Look-For-This-Sticker-With-Arrow.svg";

import GetUserOnline from "../components/GetUserOnline";

// Import the LoginModal component
import { LoginModal } from "../components/LoginModal";
import { NutritionFacts } from "../components/NutritionFacts";
import { Helmet } from "react-helmet";

// Navbar component
const Navbar = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box>
      <Box className="mobile-nav-container" onClick={handleDrawerToggle}>
        <img src={fiberLogo} />
        <CloseIcon className="close-icon" />
      </Box>

      <Box className="mobile-nav-sub-section">
        <Typography className="lets-start">
          {/* Let&#8217;s start by finding out
          <br /> if Boingo Fiber is available <br /> in your home */}
        </Typography>
        <LinkWithQuery to="/signup-form" className="avail-btn">
          Sign Up Now
        </LinkWithQuery>
      </Box>
      <Box className="mobile-nav-hr"></Box>
      <Box className="mobile-sign-in-wrapper">
        <a href={getSelfcareUrl(window.location.host)}>
          <img src={signInIcon} />
          Sign In
        </a>
      </Box>
    </Box>
  );

  const [loginModalOpen, setLoginModalOpen] = useState(false);

  const handleLoginClick = () => {
    setLoginModalOpen(true);
  };

  const handleLoginClose = () => {
    setLoginModalOpen(false);
  };

  const handleLoginSuccess = useCallback((data) => {
    console.log("Authentication successful", data);
    setLoginModalOpen(true); // Close the modal

    // Use setTimeout to delay the navigation
    setTimeout(() => {
      const currentHash = window.location.hash.split("?")[0]; // Keep the '#' but remove query params
      const basePath = currentHash.split("/")[1]; // Get the second part of the path (e.g., 'afmthm')
      const newPath = `#/${basePath}/welcome`;
      window.location.hash = newPath;
    }, 2000);
  }, []);

  return (
    <>
      <AppBar
        position="fixed"
        className="app-bar"
        sx={{ backgroundColor: "#fff" }}
      >
        <Toolbar>
          {isMobile ? (
            <>
              {/* Mobile Navigation */}
              <Box
                className="mobile-nav"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  padding: "0 16px",
                }}
              >
                <Box sx={{ width: "33%" }}></Box> {/* Spacer */}
                <Box
                  sx={{
                    width: "33%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img src={fiberLogo} className="fiber-logo" />
                </Box>
                <Box
                  sx={{
                    width: "33%",
                    display: "flex",
                    justifyContent: "flex-end",
                    marginLeft: 3,
                  }}
                >
                  <Typography
                    className="get-online "
                    sx={{
                      color: "#35c0e2",
                      cursor: "pointer",
                    }}
                    onClick={handleLoginClick}
                  >
                    Login
                  </Typography>
                </Box>
                <Drawer
                  variant="temporary"
                  open={mobileOpen}
                  anchor="right"
                  onClose={handleDrawerToggle}
                  ModalProps={{ keepMounted: true }}
                >
                  {drawer}
                </Drawer>
              </Box>
            </>
          ) : (
            <Container
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                flexFlow: "row",
                "&.MuiContainer-root": { maxWidth: '1500px' }
              }}
            >
              <Grid
                sx={{
                  width: "auto",
                  alignSelf: "flex-start",
                }}
              >
                <Grid
                  item
                  sx={{
                    textAlign: "right",
                    width: "300px",
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                ></Grid>
              </Grid>
              <Grid sx={{ textAlign: "right" }}>
                <img src={fiberLogo} className="fiber-logo" alt="Fiber Logo" />
              </Grid>
              <Grid
                sx={{
                  justifyContent: "flex-end",
                  display: "flex",
                }}
              >
                <Grid
                  sx={{
                    display: "flex",
                    width: "300px",
                    flexFlow: "row wrap",
                    alignSelf: "flex-end",
                    justifyContent: "flex-end",
                    
                  }}
                >
                  <Typography
                    className="get-online login-link"
                    onClick={handleLoginClick}
                  >
                    Have an account? Login
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          )}
        </Toolbar>
      </AppBar>
      <Toolbar /> {/* Offset for content */}
      {/* Login Modal */}
      <LoginModal
        open={loginModalOpen}
        onClose={handleLoginClose}
        onLoginSuccess={handleLoginSuccess}
      />
    </>
  );
};

const HeroSection = () => (
  <>
    <Box
      className="hero-section"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
        }}
        className="mountain-bike-img"
      >
        <Typography className="hero-desc" variant="h4" gutterBottom>
          <span>Lightning-fast internet.</span> <span>Crazy low price.</span>
        </Typography>
      </Box>
    </Box>
    <Container
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        width: "100%",
        my: 5,
      }}
    >
      <LinkWithQuery to="/signup-form" className="avail-btn large">
        Sign Up Now
      </LinkWithQuery>
    </Container>
  </>
);

// PlansSection component
const PlansSection = () => {
  const { venueId } = useParams();
  const venueData = getVenueDataFromVenueId(venueId);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();

  const handlePopOverClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // Use Grid for layout of plans
  return (
    <Box className="plan-section">
      <Container>
        <Typography className="title">
          {venueData?.module_content?.header_text}
        </Typography>

        <Box className="plan-desc router-specs">
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{
              "& .MuiPopover-paper": {
                position: "relative",
                width: "25%", // Default width for larger screens
                "@media (max-width: 767px)": {
                  width: "100%", // Width for screens less than 767px
                  marginTop: -2,
                },
                marginTop: "10px",
                paddingBottom: "15px",
                overflow: "visible",
                "&:before": {
                  content: '""',
                  position: "absolute",
                  zIndex: "10",
                  top: -16,
                  left: 20,
                  "@media(max-width: 767px)": {
                    left: 240,
                  },
                  borderBottom: "8px solid",
                  borderBottomColor: "#fff",
                  borderLeft: "8px solid transparent",
                  borderRight: "8px solid transparent",
                  borderTop: "8px solid transparent",
                },
              },
            }}
          >
            <Typography
              className="popover-title"
              sx={{
                p: 2,
                fontFamily: "Figtree-Bold",
                lineHeight: "1.2",
                color: "#000",
              }}
            >
              Make sure your equipment meets the following specs:
            </Typography>
            <List sx={{ listStyleType: "disc", margin: "-10px 0 0px 14px" }}>
              {[
                "\u2022 Supports gigabit wired speeds",
                "\u2022 Wi-Fi 5 (802.11ac) or Wi-Fi 6 (802.11ax)",
                "\u2022 80Mhz channel width",
                "\u2022 Minimum WPA2 Security",
              ].map((text, index) => (
                <ListItemText primary={text} key={index} />
              ))}
            </List>
          </Popover>

          <Typography className="router-desc sub-title">
            <span>
              Boingo Fiber is designed for service members like you. It&#8217;s
              lightning-fast and sensibly priced &mdash; with no contracts and
              no hidden fees. Just{" "}
            </span>
            <a
              href=""
              className="router-spec-anchor"
              aria-describedby={id}
              variant="contained"
              onClick={handlePopOverClick}
            >
              bring your own router
            </a>
            <span> and get in the fast lane!</span>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

// FeaturesSection component
const FeaturesSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { venueId } = useParams();
  const venueData = getVenueDataFromVenueId(venueId);
  const [nutritionFactsModalOpen, setNutritionFactsModalOpen] = useState(false);

  return (
    <>
      <Box className="feature-section">
        {/* <pre>{JSON.stringify(venueId)}</pre> */}
        <Container>
          <Box className={`plan-container
            ${venueId !== "afmthm" && 'no-discount'}
          `}>
            <Box className={`
              plan-wrap 
              expanded" 
              ${venueId !== "afmthm" && 'no-discount'}`}>
              <Typography className="plan-title">
                {venueData?.plan_name}
              </Typography>
              <Box className="description-wrap">
                {venueData?.promo?.promo_title && (
                  <Box className="desc-row">
                    <Typography className="description">
                      <img className="whisker" src={vectorLeftWhisker} />
                      {venueData?.promo?.promo_title}
                      <img className="whisker" src={vectorRightWhisker} />
                    </Typography>
                  </Box>
                )}
                <Box className="desc-row">
                  <Box className="price-wrapper">
                    {venueData?.promo && (
                      <img className="crossed-price" src={crossedPrice} />
                    )}
                    <Typography className="price">
                      <span className="dollar-sign">$</span>
                      {venueData?.promo?.promo_price || venueData?.plan_price}
                      <span className="duration">/mo</span>
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <ul className={`plan-details-list ${venueId !== "afmthm" && 'no-discount'}`}>
                    {venueData?.module_content?.list_text.map((text, index) => (
                      <li key={index}>
                        <img className="check" src={vectorCheck} />
                        <span>{text}</span>
                      </li>
                    ))}
                  </ul>
                </Box>
                <Box>
                <Typography
                    sx={{ color: "#0c93b3", cursor: "pointer" }}
                    onClick={() => setNutritionFactsModalOpen(true)}
                  >
                    View Broadband Facts for Boingo Fiber
                  </Typography>
                  <NutritionFacts 
                    open={nutritionFactsModalOpen} 
                    onClose={() => setNutritionFactsModalOpen(false)} 
                  />
                </Box>
              </Box>
            </Box>
          </Box>

          <img
            src={whyBoingoFiberSubHeader}
            className={`why-boingo-fiber-img
              ${venueId !== "afmthm" && 'no-discount'}
            `}
            width="350px"
          />

          <Box className="why-fiber-container">
            <Box className="why-fiber-box">
              <Box className="fiber-icon speed"></Box>
              <Typography className="why-fiber-title">Speed</Typography>
              <Typography className="why-fiber-desc">
                Download <em>and</em> upload speeds up to 1 gigabit!
              </Typography>
            </Box>
            <Box className="why-fiber-box">
              <Box className="fiber-icon instant"></Box>

              <Typography className="why-fiber-title">Instant on</Typography>
              <Typography className="why-fiber-desc">
                No waiting for the cable guy. Or the cable gal.{" "}
              </Typography>
            </Box>
            <Box className="why-fiber-box">
              <Box className="fiber-icon no-equip"></Box>
              <Typography className="why-fiber-title">
                No equipment charges
              </Typography>
              <Typography className="why-fiber-desc">
                Our BYO plan means you use your own equipment.{" "}
              </Typography>
              <Typography className="learn-more">
                <HashLink
                  to="#routerSection"
                  className="learn-more"
                  scroll={(el) => {
                    const offsetAmt = isMobile ? 0 : 50;
                    const desiredOffset =
                      el.getBoundingClientRect().top +
                      window.pageYOffset -
                      offsetAmt;
                    window.scrollTo({ top: desiredOffset, behavior: "smooth" });
                  }}
                >
                  Learn more about equipment!
                </HashLink>
              </Typography>
            </Box>

            <Box className="why-fiber-box">
              <Box className="fiber-icon data-caps"></Box>

              <Typography className="why-fiber-title">No data caps</Typography>
              <Typography className="why-fiber-desc">
                With Boingo Fiber, there is no throttling, and no data caps.
              </Typography>
            </Box>
            <Box className="why-fiber-box">
              <Box className="fiber-icon heart"></Box>
              <Typography className="why-fiber-title">Customer care</Typography>
              <Typography className="why-fiber-desc">
                Award-winning care from friendly humans 24/7/365.
              </Typography>
            </Box>
            <Box className="why-fiber-box">
              <Box className="fiber-icon we-get-you"></Box>

              <Typography className="why-fiber-title">We get you</Typography>
              <Typography className="why-fiber-desc">
                We understand military as many of our team are veterans or
                married to active-duty personnel.
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

// RouterSpecsSection component
const RouterSpecsSection = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handlePopOverClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Box className="router-section" id="routerSection">
        <Container>
          <Box id="SetupInSeconds" className="router-header-img">
            <img src={setUpSubHeader} />
          </Box>

          <Typography className="router-desc">
            Cable companies charge big monthly fees for their equipment. Boingo
            Fiber is different. Our &#8220;BYO&#8221; plan means you use your
            own equipment, which saves you a bundle.
          </Typography>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{
              "& .MuiPopover-paper": {
                position: "relative",
                width: "25%", // Default width for larger screens
                "@media (max-width: 767px)": {
                  width: "100%", // Width for screens less than 767px
                },
                marginTop: "10px",
                paddingBottom: "15px",
                overflow: "visible",
                "&:before": {
                  content: '""',
                  position: "absolute",
                  zIndex: "10",
                  top: -16,
                  left: 20,
                  "@media(max-width: 767px)": {
                    left: 240,
                  },
                  borderBottom: "8px solid",
                  borderBottomColor: "#fff",
                  borderLeft: "8px solid transparent",
                  borderRight: "8px solid transparent",
                  borderTop: "8px solid transparent",
                },
              },
            }}
          >
            <Typography
              className="popover-title"
              sx={{
                p: 2,
                fontFamily: "Figtree-Bold",
                lineHeight: "1.2",
                color: "#000",
              }}
            >
              Make sure your equipment meets the following specs:
            </Typography>
            <List sx={{ listStyleType: "disc", margin: "-10px 0 0px 14px" }}>
              {[
                "\u2022 Supports gigabit wired speeds",
                "\u2022 Wi-Fi 5 (802.11ac) or Wi-Fi 6 (802.11ax)",
                "\u2022 80Mhz channel width",
                "\u2022 Minimum WPA2 Security",
              ].map((text, index) => (
                <ListItemText primary={text} key={index} />
              ))}
            </List>
          </Popover>

          <Typography className="router-desc">
            <span>Already have a router you love?</span>
            Awesome. Just be sure it meets{" "}
            <a
              href=""
              aria-describedby={id}
              variant="contained"
              onClick={handlePopOverClick}
            >
              these specs.
            </a>
          </Typography>
          <Typography className="router-desc">
            <span>Need equipment?</span>
            You&#8217;re welcome to use any system {isMobile ? <br /> : ""} that
            meets{" "}
            <a
              href=""
              aria-describedby={id}
              variant="contained"
              onClick={handlePopOverClick}
            >
              these specs.
            </a>
            <br />
            Check out the Exchange for compatible routers that work with Boingo
            Fiber.
          </Typography>

          <Box className="router-bottom-section">
            <img src={worksWithBoingImg} className="works-with-boingo-img" />
            <img src={lookForSticker} className="look-for-this" />
          </Box>
        </Container>
      </Box>
    </>
  );
};

// Footer component
const Footer = () => {
  const currentYear = new Date().getFullYear();
  const { venueId } = useParams();
  const venueData = getVenueDataFromVenueId(venueId);

  return (
    <>
      <Box className="landing-page-footer-section">
        <Container>
          <LinkWithQuery to="/signup-form" className="avail-btn large">
            Sign Up Now
          </LinkWithQuery>
          <Box>
            <img src={horizontalLine} className="horizontal-line" />
            <img src={boingoLogo} className="footer-logo" />
          </Box>
          <Box>
            <Box className="footer-links-wrapper">
              <Link
                to="https://www.boingo.com/legal/boingo-privacy-policy/"
                target="_blank"
              >
                Privacy policy
              </Link>{" "}
              &nbsp;|&nbsp;&nbsp;
              <Link
                target="_blank"
                to="https://www.boingo.com/legal/website-terms-use-acceptable-use-policy/"
              >
                Terms of use
              </Link>{" "}
              &nbsp;|&nbsp;&nbsp;
              <Link
                target="_blank"
                to="https://www.boingo.com/legal/cookie-policy/"
              >
                Cookie policy
              </Link>
            </Box>
            <Box className="footer-links-wrapper">
              <Typography className="copyright">
                &copy; {currentYear} Boingo Wireless, Inc. All rights reserved.
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

const FixedSignUpForm = () => {
  return (
    <>
      <Box className="fixed-signup-form">
        <LinkWithQuery to="/signup-form" className="avail-btn">
          Sign Up Now
        </LinkWithQuery>
      </Box>
    </>
  );
};

const LandingPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [showFixedFooter, setShowFixedFooter] = useState(false);
  const [isNavHidden, setIsNavHidden] = useState(false);

  const handleScroll = () => {
    const bottomReached =
      window.innerHeight + window.scrollY >=
      document.documentElement.scrollHeight - 300;

    if (window.scrollY > 100 && !bottomReached) {
      setShowFixedFooter(true);
      setIsNavHidden(true);
    } else {
      setShowFixedFooter(false);
      setIsNavHidden(false);
    }
  };

  useEffect(() => {
    if (isMobile) {
      window.addEventListener("scroll", handleScroll);
    } else {
      setShowFixedFooter(false);
      setIsNavHidden(false);
    }
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isMobile]);

  return (
    <Box sx={{ textAlign: "center" }}>
      <Helmet>
        <title>Boingo Ethernet - Home</title>
      </Helmet>
      {!isNavHidden && <Navbar />}
      <HeroSection />
      <PlansSection />
      <FeaturesSection />
      <RouterSpecsSection />
      <Footer />
      {isMobile && showFixedFooter && <FixedSignUpForm />}
    </Box>
  );
};

export default LandingPage;
